.component {
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    z-index: -2;
    position: absolute;
    inset: 0;
    border-radius: var(--radius-8);
    transition: opacity var(--duration-450);
  }

  & > .menuIsExpanded {
    width: 100%;
  }
}

.navBarContainer {
  z-index: 0;
  position: relative;
  border-radius: var(--radius-8);
  background-color: var(--color-white);
  box-shadow: var(--shadow-lg);
  pointer-events: auto;

  & > .navBarLayout {
    height: var(--size-64);
  }
}

.componentNavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 0;

  & > .navigationLayout {
    flex: 1;
    height: 100%;
  }

  & > .actionMenuLayout {
    height: 100%;
  }

  & > .backgroundLayout {
    z-index: -1;
    position: absolute;
    width: 100%;
    top: 100%;
    margin-top: calc(-1 * var(--size-8));
  }
}

.componentBackground {
  --background-height: 0;

  pointer-events: auto;
  border-bottom-left-radius: var(--radius-8);
  border-bottom-right-radius: var(--radius-8);
  background-color: var(--color-white);
  box-shadow: var(--shadow-lg);

  &::before {
    content: '';
    display: block;
    height: var(--background-height);
    transition: height var(--duration-600) var(--ease);
    will-change: height;
  }
}

.componentNavigation {
  display: flex;
  align-items: center;
  gap: var(--size-32);
  padding-left: var(--size-16);
  transition: gap var(--duration-200) var(--ease);

  @media (--viewport-xxl) {
    gap: var(--size-64);
  }

  & > .logoContainer {
    flex-shrink: 0;
  }

  & > .menuLayout {
    height: 100%;
  }
}

.componentActionMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--size-8);
}

.logoContainer {
  & > .logoLayout {
    margin-left: var(--size-8);
  }
}

.componentMenu {
  opacity: 0;
  display: grid;
  grid-template-columns: 0fr;
  transition: var(--duration-600) ease;
  transition-property: grid-template-columns, opacity;
  pointer-events: none;
  overflow: hidden;
  padding: 2px;

  &.isExpanded {
    opacity: 1;
    grid-template-columns: 1fr;
    pointer-events: auto;
  }

  & > * {
    min-width: 0;
    width: 100%;
    height: 100%;
  }
}

.menu {
  display: flex;
  align-items: center;

  & > * {
    height: 100%;
    width: 100%;
  }
}

.componentLinkItem,
.componentSubmenuItem {
  & > .linkItem,
  & > .submenuItem {
    height: 100%;
    width: 100%;
  }
}

.componentSubmenuItem {
  & > .dropdown {
    width: 100%;
  }
}

.componentBottomBar {
  overflow-y: scroll;
}

.linkItem,
.submenuItem {
  --color-active: transparent;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 var(--size-16);
  cursor: pointer;

  &:hover,
  &.isActive {
    --color-active: var(--color-gray-900);
  }

  & > .label {
    height: 100%;
  }

  & > .dropdown {
    width: 100%;
    height: max-content;
  }
}

.componentLinkItem {
  &:hover {
    --color-active: var(--color-gray-900);
  }
}

.label {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  user-select: none;

  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid var(--color-active);
  }
}

.dropdown {
  & > .submenuLayout,
  & > .submenuDesktopLayout {
    width: 100%;
  }
}

.navigationButtons {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  padding-right: var(--size-8);
  white-space: nowrap;

  & > * {
    margin: 2px;
  }

  & > .buttonLayout {
    width: max-content;
  }
}

.fixedButtons {
  display: flex;
  align-items: center;
  gap: var(--size-16);
  padding-right: var(--size-16);

  &:empty {
    display: none;
  }
}

.contentSmall {
  padding: 2px;
}
