.component {
  & > .filterGroupLayout {
    width: 100%;
  }

  .componentActiveFilters,
  .componentFilterGroup {
    border: none;

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-color);
    }

    & > .filterHeaderLayout {
      width: 100%;
    }
  }
}

.componentFilterHeader {
  position: relative;
  text-align: left;

  & > .dropdownChevron {
    position: absolute;
    top: 50%;
    right: var(--size-32);
  }
}

.expandContainer {
  overflow: hidden;
  transition: height var(--duration-200) var(--ease-in-out);
}

.filterCheckbox {
  -webkit-appearance: auto;
}

.dropdownChevron {
  transform: translateY(-50%) rotate(180deg);
  transition: transform var(--duration-200) var(--ease-in-out);

  &.rotate {
    transform: translateY(-50%);
  }
}

.filterHeader,
.componentActiveFilters {
  padding: var(--size-24) var(--size-64) var(--size-24) var(--size-24);
}

.componentActiveFilters {
  & > .activeFiltersHeader {
    margin-bottom: var(--size-8);
  }
}

.filterHeader {
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-20);
  color: var(--color);
  font-style: italic;
  cursor: pointer;
}

.componentFilterOptionsCountry {
  & > .selectLayout {
    width: 100%;
  }
}

.componentFilterOptionsSalary {
  & > .salarySliderLayout {
    width: 100%;
  }
}

.componentFilterOptionsSalary,
.componentFilterOptionsCheckbox,
.componentFilterOptionsPill,
.componentFilterOptionsCountry {
  padding: 2px var(--size-24) var(--size-24) var(--size-24);
}

.componentFilterOptionsCheckbox {
  & > .filterValueItemLayout:not(:last-child) {
    margin-bottom: var(--size-4);
  }
}

.componentFilterOptionsPill {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);
}

.componentCheckbox,
.componentPill {
  position: relative;

  & > .checkboxInputLayout {
    position: absolute;
  }
}

.componentCheckbox {
  display: flex;
  position: relative;
  padding-left: var(--size-32);
  cursor: pointer;

  &:focus-within {
    .checkboxIndicator {
      outline: 2px solid var(--color-blue-500);
      border-radius: var(--radius-4);
    }
  }

  & > .checkboxIndicator {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
  }
}

.componentCheckboxInput {
  opacity: 0;
}

.checkboxLabel {
  color: var(--color);
  font-size: var(--font-size-18);
  line-height: var(--line-height-text);

  & a {
    color: var(--color-accent);
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.checkboxLabelMain {
  display: flex;
  justify-content: space-between;
  line-height: var(--line-height-text);
}

.checkboxIndicator {
  border: 1px solid var(--color);
  border-radius: var(--radius-4);
  padding: 3px;
}

.checkboxIndicatorChecked {
  border-color: var(--accent-color);
  background-color: var(--accent-color);
  color: var(--background-color);
}

.checkboxIndicatorPartiallyChecked {
  border-color: red;
  background-color: red;
  color: red;
}

.select {
  padding: var(--size-16) var(--size-32) var(--size-16) var(--size-16);
  border-radius: var(--radius-4);
  border: var(--shade-color);
  background-color: var(--shade-color);
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectNoValue {
  color: var(--color-light);
}

.selectChevron {
  transform: rotate(-180deg);
}

.selectChevronOpen {
  transform: rotate(0deg);
}

.selectDropdown {
  background-color: var(--color-white);
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-menu);
  padding: var(--size-8);
  overflow: auto;
}

.selectOption {
  & > .selectOptionButton {
    width: 100%;
  }
}

.selectOptionButton {
  display: block;
  display: flex;
  gap: var(--size-12);
  justify-content: space-between;
  padding: var(--size-8) var(--size-12);

  @media (--viewport-lg) {
    gap: var(--size-16);
  }

  &[data-active='true'] {
    background-color: var(--shade-color);
  }
}

.componentPill {
  padding: var(--size-12) var(--size-16);
  border-radius: var(--radius-8);
  border: 1px solid var(--shade-color);

  &:focus-within {
    outline: 2px solid var(--color-blue-500);
    border-radius: var(--radius-8);
  }

  &:hover:not(.pillChecked) {
    background-color: var(--shade-color);
  }
}

.pillChecked {
  background-color: var(--accent-color);
  color: var(--primary-element-color);
}

.activeFilterPill,
.resetFiltersPill {
  font-size: var(--font-size-14);
  border-radius: var(--radius-20);
  padding: 6px var(--size-40) 6px var(--size-12);
  position: relative;
  display: flex;
  align-items: center;

  & > .iconContainerLayout {
    position: absolute;
    right: var(--size-8);
    width: var(--size-24);
  }
}

.iconContainer {
  border-radius: 50%;
  padding: var(--size-8);
}

.activeFilterPill {
  background-color: var(--color-gray-100);

  &:hover,
  &:focus {
    .iconContainer {
      background-color: var(--color-white);
    }
  }
}

.resetFiltersPill {
  border: 1px solid var(--color-gray-100);

  &:hover,
  &:focus {
    .iconContainer {
      background-color: var(--color-gray-100);
    }
  }
}

.activeFilterPills {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);
}

.componentSalarySlider {
  display: grid;
  grid-auto-rows: auto;
  gap: var(--size-12);
}

.componentSlider {
  --size: calc(100% / var(--count));
  --total-width: 100%;

  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 30px auto;
  gap: var(--size-8);
  position: relative;
  z-index: 0;

  & > .labels {
    grid-column: 1;
    grid-row: 2;
  }

  & > .track,
  & > .minLayout,
  & > .maxLayout {
    grid-column: 1;
    grid-row: 1;
  }

  & > .track {
    height: var(--size-4);
    z-index: 1;
  }

  & > .minLayout,
  & > .maxLayout {
    z-index: 2;
  }
}

.labels {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-14);
  container-type: inline-size;
}

.track {
  display: grid;
  background-color: var(--color-gray-200);
  border-radius: var(--radius-4);

  & > .highlight {
    grid-area: 1 / 1;
    width: var(--total-width);
    left: 10%;
  }

  &::after {
    content: '';
    grid-area: 1 / 1;
    clip-path: var(--clip-path);
    background-color: var(--color-blue-300);
  }
}

.componentMin,
.componentMax {
  & > .range {
    width: var(--total-width);
    margin: 0;
    left: 10%;
  }
}

.componentMin {
  --safe-margin: 4px;
  --width-x: calc(var(--total-width) - var(--size));
  --clip-x: calc((var(--width-x) - (var(--width-x) * var(--fraction))) - var(--safe-margin));

  clip-path: inset(0 var(--clip-x) 0 calc(var(--safe-margin) * -1));
}

.componentMax {
  --safe-margin: 4px;
  --width-x: calc(var(--total-width) - var(--size));
  --clip-x: calc((var(--fraction) * var(--width-x)) - var(--safe-margin));

  clip-path: inset(0 calc((var(--safe-margin)) * -1) 0 var(--clip-x));
}

.range {
  --outline-color: transparent;

  border: none;
  appearance: none;
  background: transparent;
  pointer-events: auto;

  &:active,
  &:focus {
    &::-webkit-slider-thumb {
      --outline-color: var(--color-blue-300);
    }
  }

  &::-moz-range-track {
    background: transparent;
    border: 0;
  }

  &::-webkit-slider-runnable-track {
    background: transparent;
    border: 0;
  }

  &::-moz-range-thumb {
    border-radius: 999px;
    width: calc(100% / var(--count));
    height: 13px;
    box-sizing: border-box;
    background: var(--color-blue-300);
    box-shadow: 0 0 0 2px var(--color-white);
    outline: 2px solid var(--outline-color);
    outline-offset: 2px;
    cursor: pointer;
    appearance: none;
    border: none;
  }

  &::-webkit-slider-thumb {
    border-radius: 999px;
    width: calc(100% / var(--count));
    height: var(--size-12);
    box-sizing: border-box;
    background: var(--color-blue-300);
    box-shadow: 0 0 0 2px var(--color-white);
    outline: 2px solid var(--outline-color);
    outline-offset: 2px;
    cursor: pointer;
    appearance: none;
    border: none;
  }

  &::-moz-range-track {
    background: transparent;
  }
}
