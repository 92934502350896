.component {
  &.isLoading {
    opacity: 0.5;
  }

  & > .pagination {
    margin-top: var(--size-32);
    margin-bottom: var(--size-80);

    @media (--viewport-md) {
      margin-top: var(--size-48);
      margin-bottom: var(--size-128);
    }
  }

  & > .jobAlertLayout {
    margin-top: var(--size-16);
    margin-bottom: var(--size-16);
  }
}

.pagination {
  display: flex;
  justify-content: center;
}

.noResults,
.jobsList {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-16);
}
