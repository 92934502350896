.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-32);
  color: var(--accent-color);
  overflow: hidden;

  & > .slider {
    overflow: visible;
  }

  & > .sliderButtonsLayout {
    align-self: flex-end;
  }
}

.introContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  text-align: center;

  & > .text {
    max-width: 600px;
  }
}

.text {
  color: var(--color);
  font-size: var(--font-size-18);
  line-height: var(--line-height-text-sm);
}

.slider {
  display: flex;
  padding-top: var(--size-12);
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  & > .slideContainer {
    height: auto;
  }
}

.slideContainer {
  & > .cardLayout {
    height: 100%;
  }
}

.componentCard {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  padding: var(--size-24) var(--size-24) var(--size-32);
  border-radius: var(--radius-4);
  background-color: var(--color-white);

  &.littleCard {
    & > .buttonLayout {
      margin-top: auto;
      width: max-content;
    }
  }
}

.imageContainer {
  aspect-ratio: 1;
  border-radius: var(--radius-4);
  overflow: hidden;

  &.littleCard {
    aspect-ratio: 16 / 9;
  }
}

.textContainer {
  color: var(--color-gray-900);
}
