.component {
  --input-padding-left: var(--size-64);

  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  & > .search {
    width: 100%;
    z-index: 0;
  }

  & > .searchIconLayout {
    position: absolute;
    left: var(--size-24);
    width: var(--size-24);
    z-index: 1;
  }

  & > .searchFieldLayout {
    margin: var(--size-48) 0 var(--size-32);
  }

  & > .iconButton {
    position: absolute;
    top: 50%;
    right: var(--size-24);
    width: var(--size-32);
    height: var(--size-32);
    z-index: 1;
  }
}

.searchLabel {
  user-select: none;
  pointer-events: none;
  color: var(--color-light);
  line-height: 1;

  transform-origin: bottom left;
  transform: translateY(-2.5ex) scale(0.75);
  transition: transform var(--duration-150) var(--ease-in-out);

  &.isVisible {
    color: var(--color-light);
    transform: none;
  }
}

.search {
  position: relative;
  z-index: 0;

  & > .searchLabel {
    position: absolute;
    top: calc(50% - 1ex);
    left: var(--input-padding-left);
    z-index: 1;
  }

  & > .inputSearch {
    width: 100%;
    z-index: 0;
  }
}

.inputSearch {
  padding: var(--size-24) var(--size-80) var(--size-24) var(--input-padding-left);
  border-radius: var(--radius-4);
  border: 1px solid var(--color-light, var(--shade-color));
  background-color: var(--shade-color);
  outline: 1px solid transparent;
  transition: outline 150ms ease;

  &:placeholder-shown {
    @media not (--viewport-xs) {
      padding: var(--size-24) var(--size-24) var(--size-24) var(--input-padding-left);
    }
  }

  &::placeholder {
    user-select: none;
    color: var(--color-light);
  }

  &:focus {
    border-color: var(--accent-color);
    outline: 1px solid var(--accent-color);
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.iconButton {
  transform: translateY(-50%);
  padding: var(--size-8);
  color: var(--color);
  fill: var(--color);
  border: 1px solid currentColor;
  border-radius: var(--radius-circular);

  &:hover {
    background-color: var(--tertiary-element-background-color-hover);
    color: var(--tertiary-element-color-hover);
    fill: var(--tertiary-element-color-hover);
    border-color: var(--tertiary-element-background-color-hover);
  }
}
