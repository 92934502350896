.component {
  --card-border-color: var(--color);

  display: contents;

  &:focus-within {
    --card-border-color: var(--color-white--40);
  }
}

.componentSelectableCard {
  --opacity: 0;
  --grid-template-rows: 0fr;

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-8);
  padding: var(--size-16);
  border: 1px solid var(--card-border-color);
  border-radius: var(--radius-12);
  outline: 2px solid transparent;
  outline-offset: 0;
  transition:
    color var(--duration-150),
    border var(--duration-150),
    outline var(--duration-150);

  @media (--viewport-lg) {
    padding: 0 var(--size-24);
    min-height: 100px !important;
  }

  &:hover,
  &.isChecked {
    --opacity: 1;
    --grid-template-rows: 1fr;

    color: var(--tertiary-element-color);
    background-color: var(--color);
  }

  &:active,
  &:focus-within {
    outline-color: var(--color);
    outline-offset: 1px;
  }

  & > .iconContainer {
    flex-shrink: 0;
    width: var(--size-32);
    height: var(--size-32);
  }

  & > .input {
    position: static;
  }

  & > .textContainer {
    position: absolute;
  }
}

.input {
  cursor: pointer;
  position: relative;
  opacity: 0;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  font-style: italic;
  pointer-events: none;
}

.label {
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-20);
  user-select: none;
}

.subitleContainer {
  display: grid;
  opacity: var(--opacity);
  grid-template-rows: var(--grid-template-rows);
  transition: var(--duration-450) var(--ease);
  transition-property: grid-template-rows, opacity;

  & > * {
    min-height: 0;
  }
}

.subtitle {
  color: var(--background-color);
  font-size: var(--font-size-16);
  user-select: none;
}

.iconContainer {
  opacity: 0;
  padding: var(--size-8);
  color: var(--color);
  background-color: var(--background-color);
  border-radius: 50%;

  &.isChecked {
    opacity: 1;
  }
}
