.component {
  width: 100vw;
  max-width: 100%;
  height: max-content;
  position: fixed;
  bottom: 0;

  background-color: #141240; /* custom color for this component only */
  color: var(--color-white);
  padding: var(--size-32);
}

.layout {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  & > .linkLayout {
    width: max-content;
  }
}

.actions {
  display: flex;
  gap: var(--size-16);
  flex-direction: column;

  @media (--viewport-md) {
    gap: var(--size-24);
    flex-direction: row;
  }

  & > * {
    text-transform: uppercase;
  }
}
