.component {
  background-color: var(--background-color);
}

.relatedTilesContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-64);
  padding: var(--size-96) 0 var(--size-64);
  color: var(--color-white);
  text-align: center;

  @media (--viewport-md) {
    padding: var(--size-164) 0 var(--size-128);
  }
}

.title {
  color: var(--accent-color);
  text-align: center;
}

.bottom {
  padding: var(--size-32) 0;
  background-color: var(--background-color);
  color: var(--color);
}

.bottomContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: var(--size-48);

  @media (--viewport-md) {
    flex-direction: row;
    align-items: center;
    gap: var(--size-32);
  }
}

.icons {
  display: flex;
  gap: var(--size-24);
  align-items: center;

  & > .logo {
    width: 111px;
  }
}

.socials {
  display: flex;
  gap: var(--size-8);
  align-items: center;
  list-style: none;
}

.logo {
  display: block;
}

.componentSocialMediaLink {
  border-radius: 50%;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);

  &:focus-within,
  &:hover {
    border-color: var(--primary-element-background-color);
    background-color: var(--primary-element-background-color);
    color: var(--primary-element-color);
  }
}

.socialMediaLinkAnchor {
  & > .socialMediaIcon {
    width: var(--size-40);
    height: var(--size-40);
  }
}

.socialMediaIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.componentMenu {
  display: grid;
  grid-template-rows: repeat(var(--count), 1fr);
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  gap: var(--size-16);

  @media not (--viewport-xs) {
    grid-auto-flow: row;
  }

  @media (--viewport-md) {
    grid-template-rows: repeat(var(--count-md), 1fr);
    gap: var(--size-8) var(--size-48);
  }

  @media (--viewport-lg) {
    gap: var(--size-8) var(--size-128);
  }
}

.menuLink {
  font-size: var(--font-size-14);

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}
